import AOS from 'aos';
import 'aos/dist/aos.css';
import LazyLoad from "vanilla-lazyload";

import { initAccordions } from './components/accordions';
import { initAlternatingFeature } from './components/alternatingFeature';
import { initAnalytics } from './analytics/analytics';
import initBenefitsCards from "./components/benefitsCards";
import { initCookies } from './cookies/cookies';
import circleHover from './components/circleHover';
import { initModals } from './components/modals';
import { initForms } from './components/forms';
import lazyLoadEntries from './lazyLoad';
import { initLegacyMethods } from './legacy/legacy';
import { megaNav, toggleActiveNav } from './components/navigation';
import { heroHexParallax } from './components/parallaxes';
import { initTabs } from './components/tabs';
import { initShows } from './components/shows';

(function () {
  switch (document.readyState) {
    case "interactive":
      AOS.init()

      // Accordions
      initAccordions()

      // Alternating Feature
      initAlternatingFeature()

      // Analytics
      initAnalytics()

      // Benefits cards
      let benefitCards = document.querySelectorAll('.card') ?? false
      if (benefitCards) {
        let frame = document.querySelector('.panes')
        initBenefitsCards(benefitCards)
      }

      // Cookies
      initCookies()

      // Circle Hover
      let circleHoverSvgEls = document.querySelectorAll('.js-circle-hover') ?? false
      if (circleHoverSvgEls) {
        Array.from(circleHoverSvgEls, target => circleHover(target))
      }

      // Forms
      initForms()

      // Hero Hex Parallax
      let hexParallaxEl = document.querySelector('.js-parallax') ?? false
      if (hexParallaxEl) {
        heroHexParallax(hexParallaxEl)
      }

      // Lazy Load
      let lazyPreloader = document.querySelector('.lazy-entries__preloader') ?? false
      if (lazyPreloader) {
        let innerHeight = window.innerHeight;

        let rootMargin = '0px 0px ' + (innerHeight + 144) + 'px 0px';

        const lazyObserverOptions = {
          threshold: 1.0,
          rootMargin: '0px'
        }

        const lazyObserver = new IntersectionObserver(lazyLoadEntries, lazyObserverOptions);
        lazyObserver.observe(lazyPreloader);
      }

      // Legacy Methods
      initLegacyMethods()

      // Mega nav
      let megaNavEls = document.querySelectorAll('.dropdown--mega') ?? false
      if (megaNavEls && window.outerWidth > 990) {
        Array.from(megaNavEls, target => megaNav(target))
      }

      // Modals
      initModals()

      // Shows
      initShows()

      // Toggle active nav state
      let navControlEls = document.querySelectorAll('.js-nav') ?? false
      if (navControlEls) {
        Array.from(navControlEls, target => toggleActiveNav(target))
      }

      // Tabs
      initTabs()

      break;
  }
})();