import Splide from "@splidejs/splide";

import Swiper from "swiper";
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const _loopedSinglePaginatedOpt = {
  modules: [Pagination],
  loop: true,
  slidesPerView: 1,
  lazyLoad: true,
  pagination: {
    clickable: true
  }
}

const hexagonHero = (target = false) => {
  if (target) {
    const hexesSlider = new Splide(target, {
      type: "loop",
      perPage: 3,
      arrows: false,
      pagination: false,
      focus: "center",
      trimSpace: false,
      fixedWidth: "25vw",
      autoplay: true,
      interval: 1500000,
      speed: 500,
      lazyLoad: true
    }).mount();
  } else {
    console.warn('Hexagon Hero Slider: No target element given')
  }
}

const squareSlider = (target = false) => {
  if (target) {
    let options = _loopedSinglePaginatedOpt
    options.spaceBetween = 24
    options.pagination.el = '.slick-dots'

    const squareSliderEl = new Swiper(target, options)
  } else {
    console.warn('Square Slider: No target element given')
  }
}

const testimonialsSlider = (target = false) => {
  if (target) {
    let options = _loopedSinglePaginatedOpt
    options.pagination.el = '.slick-dots'
    const testimonialsSliderEl = new Swiper(target, _loopedSinglePaginatedOpt)
  } else {
    console.warn('Testimonials Slider: No target element given')
  }
}

const defaultSlider = (target = false) => {
  if (target) {
    console.log('Init Default Sliders')

    const defaultSliderEl = new Swiper(target, {
      modules: [Navigation],
      loop: true,
      slidesPerView: 1,
      autoplay: {
        delay: 4000
      },
      navigation: {
        nextEl: '.js-slider-next',
        prevEl: '.js-slider-prev'
      }
    })
  } else {
    console.warn('Default Slider: No target element given')
  }
}

const initShows = () => {
  let hexHeroSliderEls = document.querySelectorAll('.hero__hexes-slider--splide') ?? false
  if (hexHeroSliderEls) {
    Array.from(hexHeroSliderEls, target => hexagonHero(target))
  }

  let squareSliderEls = document.querySelectorAll('.js-square-slider') ?? false
  if (squareSliderEls) {
    Array.from(squareSliderEls, target => squareSlider(target))
  }

  let testimonialsSliderEls = document.querySelectorAll('.js-testimonials') ?? false
  if (testimonialsSliderEls) {
    Array.from(testimonialsSliderEls, target => testimonialsSlider(target))
  }

  let defaultSliderEls = document.querySelectorAll('.js-slider') ?? false
  if (defaultSliderEls && (window.outerWidth >= 768)) {
    Array.from(defaultSliderEls, target => defaultSlider(target))
  }
}

export { initShows }