import { fadeOut } from "../animations/animations"

/**
 * Load more "Our People" elements
 * - Refactored from Ajax
 * @param {Element} target 
 */
const fetchPeople = (target = false) => {
  if (target) {
    let baseUrl = '/ajax/people/'
    let page = target.dataset.page
    let exclusions = target.dataset.exclude.split(',').toString()
    let parent = document.querySelector('.js-ajax-target')

    if (!target.classList.contains('js-disabled')) {
      target.classList.add('js-disabled')
      fetch(`${baseUrl}${page}?exclude=${exclusions}`, {
        method: 'GET',
        headers: {
          'Accept': 'text/html',
          'X-Requested-With': 'XMLHttpRequest',
        }
      })
        .then(response => response.text())
        .then(res => {
          let currentPage = page.split('p')[1]
          let newPage = parseInt(currentPage) + 1

          let parser = new DOMParser()
          let markup = parser.parseFromString(res, 'text/html')

          let newCols = markup.querySelectorAll('.column')
          Array.from(newCols, el => {
            el.setAttribute('data-aos', 'fade-in')
            parent.appendChild(el)
          })
          console.log(res)

          if (newCols.length == 100) {
            target.dataset.page = `p${newPage}`
            target.classList.remove('js-disabled');
          } else {
            fadeOut(target.closest('div'))
            target.closest('div').style.display = 'none';
          }
        })
        .catch(err => {
          console.warn(err)
        })
    }
  } else {
    console.warn('Fetch People: No target element given')
  }
}

const initLegacyMethods = () => {
  let fetchPeopleEls = document.querySelectorAll('.js-ajax-people') ?? false
  if (fetchPeopleEls) {
    Array.from(fetchPeopleEls, target => target.addEventListener('click', () => {
      fetchPeople(target)
    }))
  }
}

export { initLegacyMethods }