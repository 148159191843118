export default function circleHover (target = false) {
  if (target) {
    const tooltipContainerCls = ['tip-container', 'js-tip-container']

    let tooltipEls = target.querySelectorAll('.js-circle-tooltip')
    Array.from(tooltipEls, tooltipEl => {
      tooltipEl.addEventListener('mouseenter', (ev) => {
        let existingTooltipContainerEl = target.querySelector('.js-tip-container') ?? false

        if (!existingTooltipContainerEl) {
          let tooltipContent = tooltipEl.querySelector('desc').innerHTML

          let tooltipContainerEl = document.createElement('div')
          tooltipContainerEl.classList.add(...tooltipContainerCls)
          tooltipContainerEl.innerHTML = tooltipContent
          
          target.closest('.js-animate').appendChild(tooltipContainerEl)
        }
      })

      tooltipEl.addEventListener('mouseleave', (ev) => {
        let existingTooltipContainerEl = target.closest('.js-animate').querySelector('.js-tip-container') ?? false
        if (existingTooltipContainerEl) {
          existingTooltipContainerEl.remove()
        }
      })
    })
  } else {
    console.warn('Circle Hover: No target element given')
  }
}