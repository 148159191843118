 const toggleActiveNav = (target = false) => {
  if (target) {
    target.addEventListener('click', () => {
      target.classList.toggle('is-active')
    })
  } else {
    console.warn('Toggle Active Nav: No target element given')
  }
 }
 
 const megaNav = (target = false) => {
  if (target) {
    let parent = target.closest('.nav__item')
    let parentPosL = parent.offsetLeft + 30
    let parentPosR = (window.outerWidth - (parent.outerWidth + parentPosL))

    if (parentPosL < 350) {
      target.style = `left: ${((parentPosL * -1) + 20)}px;`
    }

    if (parentPosR < 350) {
      target.style = `right: ${((parentPosR * -1) + 20)}px;`
    }
  } else {
    console.warn('Mega Nav: No target element given')
  }
}
 
 export { megaNav, toggleActiveNav }