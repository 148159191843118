/**
 * Control styling of alternating feature sections
 * - based on position in the viewport
 * 
 * @param {Element} target 
 */
function alternatingFeatureOnScroll (target = false) {
  if (target) {
    window.addEventListener('scroll', (ev) => {
      let trigHeight = window.innerHeight / 1.3
      let targetPos = target.offsetTop
      let windowScrollTop = window.scrollY

      const activeClass = 'alternating-feature__item--viewport-in'
      const inactiveClass = 'alternating-feature__item--viewport-out'
  
      if (targetPos <= (windowScrollTop + trigHeight)) {
        target.classList.remove(inactiveClass)
        target.classList.add(activeClass)
      } else {
        target.classList.remove(activeClass)
        target.classList.add(inactiveClass)
      }
    })
  } else {
    console.warn('Alternating Feature: No target element given')
  }
}

const initAlternatingFeature = () => {
  if (window.outerWidth > 800) {
    let alternatingFeatureEls = document.querySelectorAll('.alternating-feature__item')
    if (alternatingFeatureEls) {
      Array.from(alternatingFeatureEls, target => alternatingFeatureOnScroll(target))
    }
  }
}

export { initAlternatingFeature }