/**
 * Control styling on blog signup form
 * @param {Element} target 
 */
const blogSignupForm = (target = false) => {
  if (target) {
    // Input labels
    let inputFieldEls = target.querySelectorAll('.form__field input')
    Array.from(inputFieldEls, fieldEl => {
      let formLabelEl = fieldEl.closest('.form__row').querySelector('.form__label')

      fieldEl.addEventListener('focus', (ev) => {
        formLabelEl.style.zindex = '-1'
      })

      fieldEl.addEventListener('focusout', (ev) => {
        formLabelEl.style.zindex = '1'
      })
    })

    // Box Narrow
    let formTriggerEl = target.querySelector('.form-trigger')
    let formBoxNarrow = target.querySelector('.box-narrow')
    if (formTriggerEl && formBoxNarrow) {
      formTriggerEl.addEventListener('click', () => {
        formBoxNarrow.classList.add('active')
      })
    }
  } else {
    console.warn('Blog Signup Form: No target element given')
  }
}

/**
 * Control submission on signup optin form
 * @param {Element} target 
 */
const signupOptInForm = (target = false) => {
  if (target) {
    const noticeEl = document.createElement('p').innerHTML = 'Please confirm your consent.'
    noticeEl.style = 'color:red;'

    let formEl = target.closest('form')
    formEl.querySelector('input[type="submit"]').addEventListener('click', (ev) => {
      ev.preventDefault();
      if (!target.checked) {
        formEl.closest('.mc-field-group').appendChild(noticeEl)
      }
    })
  } else {
    console.warn('Signup Optin Form: No target element given')
  }
}

const initForms = () => {
  // Blog email signup form
  let blogEmailFormEl = document.querySelector('.blog-email-signup-form') ?? false
  if (blogEmailFormEl) {
    blogSignupForm(blogEmailFormEl)
  }

  // Sign up opt in forms
  let signupOptInForms = document.querySelectorAll('.js-sign-up-optin') ?? false
  if (signupOptInForms) {
    Array.from(signupOptInForms, formEl => signupOptInForm(formEl))
  }

}

export { initForms }