import { fadeOut } from "../animations/animations"

/**
 * Construct a modal element
 * - Reccomend replacing this with a webcomponent
 * 
 * @param {String} content 
 * @param {Boolean} simpleModal 
 */
const _constructModal = (
    content = false,
    simpleModal = false
  ) => {
    // Create elements
    const modalCoverEl = document.createElement('div')
    modalCoverEl.classList.add('modal-cover')

    const modalEl = document.createElement('div')
    modalEl.classList.add('modal')
    if (simpleModal) {
      modalEl.classList.add('modal--flat')
    }

    const modalControlEl = document.createElement('a')
    modalControlEl.classList.add('modal-close','js-modal-close')
    modalControlEl.innerHTML = '&times;'

    const modalContentEl = document.createElement('div')
    modalContentEl.classList.add('modal-content')

    if (content) {
      modalContentEl.innerHTML = content
    }

    // Merge into single element
    modalEl.append(modalControlEl, modalContentEl)
    modalCoverEl.appendChild(modalEl)

    // Add close control event listener
    modalControlEl.addEventListener('click', (ev) => {
      ev.preventDefault()
      fadeOut(modalCoverEl)
      modalCoverEl.remove()
      document.querySelector('body').classList.remove('modal-open')
    })

    return modalCoverEl;
}

const modal = () => {
  let modalTrigerEls = document.querySelectorAll('.js-modal-trigger') ?? false
  if (modalTrigerEls) {
    const body = document.querySelector('body')

    Array.from(modalTrigerEls, trigger => trigger.addEventListener('click', (ev) => {
      let titleContent = trigger.innerHTML
      let displayTitle = trigger.dataset.modalTitle ?? false
      let flatModal = (trigger.dataset.modalVideo) ? true : false
      let modalContent = trigger.dataset.modalContent ?? false

      let newModal = (displayTitle) 
        ? _constructModal(`<h4>${titleContent}</h4>${modalContent}`, flatModal) 
        : _constructModal(modalContent, flatModal)

      
      body.classList.add('modal-open')
      body.appendChild(newModal)
    }))
  } else {
    console.warn('Modal: No target element given')
  }
}

/**
 * TBC
 */
const infoModal = () => {
  const body = document.querySelector('body')
  body.addEventListener('click', function (ev) {
    if (ev.target.matches('.js-info-modal')) {
      ev.target.closest('.info-cards__item').querySelector('.info-cards__modal').classList.toggle('active')
    }
  })
}

const initModals = () => {
  modal()
  infoModal()
}

export { initModals }