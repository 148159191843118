// Animation Helpers
/**
 * ...
 * @param {Element} el 
 * @param {Int} i 
 */
const _triggerAnimationTimeout = (el, i) => {
  setTimeout(() => {
    (el.nodeName === 'svg') 
      ? el[0].classList.add('animated')
      : el.classList.add('animated')
  }, i * 250)
}

/**
 * ...
 * @param {Element} target 
 */
const triggerAnimation = (target) => {
  if (target) {
    let pos = target.offsetTop
    let triggerVal = (pos - window.outerHeight / 1.25)
    let winScrollPos = window.scrollY

    let childEls = target.querySelectorAll('.js-animate')

    if (winScrollPos > triggerVal || target.classList.contains('js-anim-onload')) {
      Array.from(childEls, (el, i) => {
        _triggerAnimationTimeout(el, i)
      })
    } else {
      window.addEventListener('scroll', () => {
        winScrollPos = window.scrollY

        if (winScrollPos > triggerVal) {
          Array.from(childEls, (el, i) => {
            _triggerAnimationTimeout(el, i)
          })
        }
      })
    }
  } else {
    console.warn('Trigger Animation: No target element given')
  }
}

// Animations
/**
 * Fade element out
 * @param {*} el 
 * @param {*} interval 
 */
const fadeOut = (el, interval = 150) => {
  let opacity = 1
  let intervalId = `${el}-0`

  setInterval(() => {
    if (opacity > 0) {
      opacity = opacity - 0.1
      el.style.opacity = opacity
    } else {
      clearInterval(intervalId)
    }
  }, interval);
}

/**
 * Fade element in
 * @param {*} el 
 * @param {*} interval 
 */
const fadeIn = (el, interval = 150) => {
  let opacity = 0
  let intervalId = `${el}-0`

  setInterval(() => {
    if (opacity < 1) {
      opacity = opacity + 0.1
      el.style.opacity = opacity
    } else {
      clearInterval(intervalId)
    }
  }, interval);
}

/**
 * Scroll to element
 * @param {Element} target 
 */
const scrollTo = (target) => {
  if (target) {
    target.addEventListener('click', () => {
      let scrollPos = document.querySelector('.js-anim-trigger:nth-child(1)').offsetTop

      document.querySelector('body').animate([
        { top: this.scrollY },
        { top: scrollPos }
      ], {
        duration: 500,
        iterations: 1
      })
    })
  } else {
    console.warn('Scroll To: No target element given')
  }
}

export { triggerAnimation, scrollTo, fadeOut, fadeIn }