import Parallax from "parallax-js"

const heroHexParallax = (target = false) => {
  if (target) {
    let parallaxInst = new Parallax(target, {
      hoverOnly: true
    })
  } else {
    console.warn('Hero Hex Parallax: No target element given')
  }
}

export { heroHexParallax }