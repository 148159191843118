let lazyOffset = 1;
let moreToDisplay = true;
let isFetching = false;
const shimmerRow = document.querySelector('.shimmer-cards');

export default function lazyLoadEntries(entries, observer) {   
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            const lazyList = entry.target.previousElementSibling;
            const $lazyPreloader = entry.target;

            const settings = JSON.parse($lazyPreloader.dataset.settings);
            settings.offset = lazyOffset * 12;

            const params = '?' + new URLSearchParams(settings).toString();

            if (moreToDisplay && !isFetching) {
                isFetching = true;
                fetch($lazyPreloader.dataset.element + params, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                    }
                })
                .then(response => response.json())
                .then(result => {
                    if (result.data.length > 0) {
                        let length = result.data.length;

                        result.data.forEach(el => {
                            setTimeout(() => {
                                lazyList.innerHTML += el.html
                            }, 120);
                        });

                        lazyOffset++;

                        if ((settings.offset + 1) >= settings.count) {
                            shimmerRow.remove();
                            moreToDisplay = false;
                            observer.unobserve(target.element);
                        }
                    } else {
                        shimmerRow.remove();
                        moreToDisplay = false;
                        observer.unobserve(target.element);
                    }

                    isFetching = false;
                });
            }
        }
    })
}