import Cookies from "js-cookie"
import { slideDown, slideUp } from "es6-slide-up-down"

const _setHGemCookie = (controlEl, el) => {
  if (controlEl && el) {
    controlEl.addEventListener('click', (ev) => {
      Cookies.set("HGem_Cookie", "true", { expires: 28 })
      slideUp(el)
    })
  } else {
    console.warn('Set HGem Cookie: No target element given')
  }
}

const initCookies = () => {
  let cookieDismissEl = document.querySelector('.js-cookie-dismiss')
  let cookieBarEl = document.querySelector('.js-cookie-bar')
  if (cookieDismissEl && cookieBarEl) {
    let cookieExists = Cookies.get("HGem_Cookie")

    if (cookieExists != undefined) {
      slideUp(cookieBarEl)
    } else {
      slideDown(cookieBarEl)
      _setHGemCookie(cookieDismissEl, cookieBarEl)
    }
  }
}

export { initCookies }