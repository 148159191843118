import { fadeIn, fadeOut } from "../animations/animations"

/**
 * Control the state of active & inactive tabs
 * @param {Event} ev 
 * @param {Element} el 
 * @param {NodeList} tabEls 
 * @param {Element} parent 
 */
const tabsLegacy = (target, tabEls) => {
  if (target) {
    Array.from(tabEls, tab => tab.addEventListener('click', () => {
      if (!tab.classList.contains('active')) {
        let activePaneEl = target.querySelector('.js-tab:visible')
        let newActivePaneEl = target.querySelector(`.js-tab[data-tab="${tab.dataset.tab}"]`)

        target.querySelector('li.active').classList.remove('active')
        tab.classList.add('active')
    
        fadeOut(activePaneEl)
        fadeIn(newActivePaneEl)
      }
    }))
  } else {
    console.warn('Tabs (legacy): No target element given')
  }
}

/**
 * ...
 * @param {Element} target 
 */
const _pricingLegacyTabActiveCls = 'button--tab-hover'
const _pricingLegacyPaneActiveCls = 'pricing-tabs__current-tab'
const pricingTabsLegacy = (target = false) => {
  if (target) {
    let tabEls = target.querySelectorAll('.pricing-tabs__tabs li')

    Array.from(tabEls, tab => tab.addEventListener('click', () => {
      let tabId = tab.dataset.tab

      target.querySelector(`.${_pricingLegacyTabActiveCls}`).classList.remove(_pricingLegacyTabActiveCls)
      target.querySelector(`.${_pricingLegacyPaneActiveCls}`).classList.remove(_pricingLegacyPaneActiveCls)

      tab.classList.add(_pricingLegacyTabActiveCls)
      target.querySelector(`#${tabId}`).classList.add(_pricingLegacyPaneActiveCls)
    }))
  } else {
    console.warn('Pricing Tabs (legacy): No target element given')
  }
}

const contentTabs = (target = false) => {
  if (target) {
    let tabs = target.querySelectorAll('.tab');
  
    tabs.forEach(tab => {
      tab.addEventListener('click', (ev) => {
        let pane = target.querySelector(`[data-tab-pane="${tab.dataset.tab}"]`);

        if (tab.dataset.activeTab === '0') {
          let activeTab = target.querySelector('[data-active-tab="1"]');
          let activePane = target.querySelector('[data-active-pane="1"]');

          if (activeTab && activePane) {
            activeTab.dataset.activeTab = 0;
            activePane.dataset.activePane = 0;
          }

          tab.dataset.activeTab = 1;
          pane.dataset.activePane = 1;
        }
      });
    });
  } else {
    console.warn('Content Tabs: No target element given')
  }
}

const initTabs = () => {
  let tabsLegacyEls = document.querySelectorAll('.tabs--legacy') ?? false
  if (tabsLegacyEls) {
    Array.from(tabsLegacyEls, target => {
      let tabEls = target.querySelectorAll('li')
      tabsLegacy(target, tabEls)
    })
  }

  let pricingTabsLegacyEls = document.querySelectorAll('.pricing-tabs') ?? false
  if (pricingTabsLegacyEls) {
    Array.from(pricingTabsLegacyEls, target => pricingTabsLegacy(target))
  }

  let contentTabSections = document.querySelectorAll('.content-tabs') ?? false
  if (contentTabSections) {
    Array.from(contentTabSections, section => contentTabs(section))
  }
}

export { initTabs }