/**
 * GA Tracking on outbound links
 * @param {Element} target 
 */
const outboundGA = (target = false) => {
  if (target) {
    target.addEventListener('click', () => {
      let url = target.getAttribute('href')

      if (
        target.getAttribute('target') === undefined ||
        target.getAttribute('target') === false
      ) {
        ga("send", "event", "outbound", "click", url, {
					transport: "beacon",
					hitCallback: function() {
						document.location = url;
					}
				})
      } else {
        ga("send", "event", "outbound", "click", url, {
					transport: "beacon",
					hitCallback: function() {}
				})
      }
    })
  } else {
    console.warn('Outbound GA: No target element given')
  }
}

/**
 * Tracking on Contact form submission
 * @param {Element} target 
 */
const contactGA = (target = false) => {
  if (target) {
    target.addEventListener('submit', () => {
      ga("send", "event", "Contacts", "Contact form submitted", "")
    })
  } else {
    console.warn('Contact GA: No target element given')
  }
}

const initAnalytics = () => {
  let outboundEls = document.querySelectorAll('.js-gaTrack') ?? false
  if (outboundEls) {
    Array.from(outboundEls, el => outboundGA(el))
  }

  let contactEls = document.querySelectorAll('.form--contactUs') ?? false
  if (contactEls) {
    Array.from(contactEls, el => contactGA(el))
  }
}

export { initAnalytics }