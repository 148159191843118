import {slideUp, slideDown} from 'es6-slide-up-down';

/**
 * Control custom simple accordion elements across the site
 * @param {Element} targetClass 
 */
const simpleAccordion = (targetClass = false) => {
  if (targetClass) {
    let accordions = document.querySelectorAll(targetClass)
    Array.from(accordions, accordion => {
      let trigger = accordion.querySelector('button')
      let draw = (targetClass != '.js-nav-accordion') 
        ? accordion.querySelector('.alternating-block__accordion-draw')
        : accordion.querySelector('.dropdown')

      slideUp(draw)

      trigger.addEventListener('click', () => {
        if (trigger.classList.contains('active')) {
          trigger.classList.remove('active')
          slideUp(draw)
        } else {
          trigger.classList.add('active')
          slideDown(draw)
        }
      })
    })
  } else {
    console.warn('Simple Accordion: No target element given')
  }
}

const initAccordions = () => {
  (window.outerWidth < 990) ? simpleAccordion('.js-nav-accordion') : simpleAccordion('.js-simple-accordion')

  let accordionEls = document.querySelectorAll('.js-accordion') ?? false
  if (accordionEls) {
    Array.from(accordionEls, target => accordion(target))
  }
}

export { initAccordions }