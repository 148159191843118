export default function initBenefitsCards (cards) {
    cards.forEach(card => {
        let handle = card.dataset.tab;
        let pane = document.querySelector(`[data-tab-content="${handle}"]`);
        let btn = card.querySelector('.button');

        card.addEventListener('click', function(ev) {
            if (!card.classList.contains('active') && !pane.classList.contains('active')) {
                let activeCard = document.querySelector('.card.active');
                let activePane = document.querySelector('.pane.active');

                if (activeCard && activePane) {
                    let activeCardBtn = activeCard.querySelector('.button');
                    
                    activeCard.classList.remove('active');
                    activePane.classList.remove('active');

                    if (activeCardBtn.innerHTML.includes('Read less')) {
                        activeCardBtn.innerHTML = 'Read more';
                    }
                }

                if (btn.innerHTML.includes('Read more')) {
                    btn.innerHTML = 'Read less';
                }

                card.classList.add('active');
                pane.classList.add('active');
            } else {

                if (btn.innerHTML.includes('Read less')) {
                    btn.innerHTML = 'Read more';
                }

                card.classList.remove('active');
                pane.classList.remove('active');
            }
        });
    });
}